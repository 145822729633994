import React, { useEffect, useState } from "react";
import { apiUrl, Header, HeaderItem, Service, useAuth } from "@hex-labs/core";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Box,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { HEXATHON_ID } from "./App";
const Navigation: React.FC = () => {
  const currentHexathon = HEXATHON_ID;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user } = useAuth();
  const [role, setRoles] = useState<any>({
    member: false,
    exec: false,
    admin: false,
  });
  const changeEvent = () => {
    window.localStorage.removeItem("hexathonId");
  };
  const logOut = async () => {
    window.localStorage.removeItem("hexathonId");
    await axios.post(apiUrl(Service.AUTH, "/auth/logout"));
    window.location.href = `https://login.hexlabs.org/login?redirect=${window.location.href}`;
  };
  useEffect(() => {
    const getRoles = async () => {
      if (user?.uid) {
        const response = await axios.get(
          apiUrl(Service.USERS, `/users/${user?.uid}`)
        );
        setRoles({ ...response.data.roles });
      }
    };
    getRoles();
  }, [user?.uid]);
  const rightHeaderItem = currentHexathon ? (
    <HeaderItem onMouseEnter={onOpen} onMouseLeave={onClose}>
      <Menu isOpen={isOpen}>
        <MenuButton>
          <Box display="flex" alignItems="center" role="group">
            <Text marginLeft="5px">{"HackGT 9"}</Text>
            <ChevronDownIcon marginLeft="3px" />
          </Box>
        </MenuButton>
        <MenuList lineHeight="2" onClick={onClose}>
          <Link to="/" onClick={logOut}>
            <MenuItem>Sign Out</MenuItem>
          </Link>
        </MenuList>
      </Menu>
    </HeaderItem>
  ) : (
    <Link to="/" onClick={logOut}>
      <HeaderItem>Sign Out</HeaderItem>
    </Link>
  );
  const rightHeaderItemMobile = currentHexathon ? (
    <>
      <Link to="/" onClick={changeEvent}>
        <HeaderItem>Change Event</HeaderItem>
      </Link>
      <Link to="/" onClick={logOut}>
        <HeaderItem>Sign Out</HeaderItem>
      </Link>
    </>
  ) : (
    <Link to="/" onClick={logOut}>
      <HeaderItem>Sign Out</HeaderItem>
    </Link>
  );
  return (
    <Header rightItem={rightHeaderItem} rightItemMobile={rightHeaderItemMobile}>
      {currentHexathon && (
        <>
          <Link to={`/`}>
            <HeaderItem>Swag Shop</HeaderItem>
          </Link>
          {role.member && (
            <>
              <Link to={`/item-checkout`}>
                <HeaderItem>Item Checkout</HeaderItem>
              </Link>
            </>
          )}
        </>
      )}
    </Header>
  );
};
export default Navigation;
