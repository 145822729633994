import React, { useEffect, useState } from "react";
import { LoadingScreen, useAuth } from "@hex-labs/core";
import { Service, apiUrl, ErrorScreen } from "@hex-labs/core";
import axios from "axios";
import { HEXATHON_ID } from "../../App";

const AuthCheck: React.FC<any> = (props) => {
  const { user } = useAuth();
  const [loadingUser, setLoadingUser] = useState(true);
  const [validUser, setValidUser] = useState<boolean>(false);
  const [userError, setUserError] = useState(null);

  useEffect(() => {
    const getUser = async () => {
      if (user) {
        try {
          await axios.post(
            apiUrl(
              Service.HEXATHONS,
              `/hexathon-users/${HEXATHON_ID}/users/${user?.uid}/actions/check-valid-user`
            )
          );
          setValidUser(true);
        } catch (err: any) {
          setUserError(
            err.response?.data?.message ? Error(err.response.data.message) : err
          );
        } finally {
          setLoadingUser(false);
        }
      }
    };
    getUser();
  }, [user]);

  if (loadingUser) {
    return <LoadingScreen />;
  }

  if (userError) {
    return <ErrorScreen error={userError} />;
  }

  if (!validUser) {
    return <ErrorScreen error={Error("an error has occured")} />;
  }

  return props.children;
};

export default AuthCheck;
