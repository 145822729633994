import React, { useEffect, useState } from "react";
import "./App.css";
import useAxios from "axios-hooks";
import { User } from "./types/User";
import UserProfile from "./components/profile/UserProfile";
import {
  BrowserRouter as Router,
  Routes,
  Navigate,
  Route,
} from "react-router-dom";
import ManageEvents from "./components/events/ManageEvents";
import ManageUsers from "./components/users/ManageUsers";
import ShopKeeper from "./components/shops/ShopKeeper";
import Base from "./components/admin/Base";
import SwagShop from "./components/swag/SwagShop";
import Navigation from "./Navigation";
import Leaderboard from "./components/leaderboard/Leaderboard";
import IndividualShop from "./components/shops/Shop";
import { initializeApp } from "firebase/app";
import { setPersistence, getAuth, inMemoryPersistence } from "firebase/auth";
import {
  Header,
  useLogin,
  LoadingScreen,
  apiUrl,
  Service,
  AuthProvider,
  Footer,
  HeaderItem,
} from "@hex-labs/core";
import { ChakraProvider } from "@chakra-ui/react";
import axios from "axios";
import AuthCheck from "./components/shops/AuthCheck";
import UsersTable from "./components/admin/UsersTable";

export const app = initializeApp({
  apiKey: "AIzaSyCsukUZtMkI5FD_etGfefO4Sr7fHkZM7Rg",
  authDomain: "auth.hexlabs.org",
});
export const HEXATHON_ID = "62d9ed68d0a69b88c06bdfb2";

setPersistence(getAuth(app), inMemoryPersistence);

axios.defaults.withCredentials = true;

const App: React.FC = () => {
  const [loginLoading, loggedIn] = useLogin(app);

  if (loginLoading) {
    return <LoadingScreen />;
  }

  if (!loggedIn) {
    window.location.href = `https://login.hexlabs.org?redirect=${window.location.href}`;
    return <LoadingScreen />;
  }

  return (
    <AuthProvider app={app}>
      <div
        className="App"
        style={{
          backgroundColor: "#ffffff", //light blue
          minHeight: "100vh", // ???auto
          minWidth: "100vw",
        }}
      >
        <AuthCheck>
          <ChakraProvider></ChakraProvider>
          <Navigation />
          <Routes>
            <Route path="/" element={<Navigate to="/swag" />} />
            <Route path="/swag" element={<SwagShop />} />
            <Route path="/item-checkout" element={<UsersTable />} />

            {/* <Route path="/profile" element={<UserProfile user={user} />}/> */}
            {/* <Route path="/buy/:itemId" element={<Confirmation user={user} />} />
          <Route path="/leaderboard" element={<Leaderboard />} /> */}
            {/* <Route path="/shopkeeper" element={<IndividualShop />}/> */}
            {/* <Route path="/shopkeeper/:shopId" element={<IndividualShop />} />
          <Route path="/admin" element={<Base user={user} />} />  */}
            {/* item pickup */}
            {/*
            <Route
                  path="/events"
                  element={<ManageEvents user={user} />}
                /> */}
            {/* <Route
                  path="/users"
                  element={<ManageUsers user={user} />}
                ></Route> */}
          </Routes>
          <Footer></Footer>
        </AuthCheck>
      </div>
    </AuthProvider>
  );
};

export default App;
